import { useLivePreviewWidgetSettings } from "../hooks/useLivePreviewWidgetSettings";
import { useContacts, useWidgetSettings } from "../hooks/useWidgetSettings";
import { Widget } from "./Widget";

interface WidgetDataLoaderProps {
  shopName: string;
  isDevelopmentMode: boolean;
}

type Data = { data: Record<string, string | boolean | number> };
type Error = unknown;

export function WidgetDataLoader({
  isDevelopmentMode,
  shopName,
}: WidgetDataLoaderProps) {
  const { data: developmentData } = useLivePreviewWidgetSettings<Data>();
  const { data } = useWidgetSettings<Data, Error>(shopName);
  const {
    data: contactsData,
    loading,
    error,
  } = useContacts<any, any>(shopName);

  if (loading) return null;

  if (!isDevelopmentMode && data) {
    return <Widget contacts={contactsData?.data} data={data.data} />;
  }

  console.log({ developmentData });

  if (isDevelopmentMode && developmentData?.data) {
    return <Widget contacts={contactsData?.data} data={developmentData.data} />;
  }

  return null;
}
