import { useCallback, useEffect, useState } from "react";

export function useLivePreviewWidgetSettings<D>() {
  const [data, setData] = useState<D>();
  const [loading, setLoading] = useState(true);

  const messageReceiver = useCallback(
    (e: MessageEvent) => {
      console.log("Message received from the child: " + e.data); // Message received from child

      setData({ data: e.data.formState } as any);
      setLoading(false);
    },
    [setData, setLoading]
  );

  // let the frontend know you want to receive messages
  useEffect(() => {
    window.parent.postMessage("INITIATED", "*");
  }, []);

  useEffect(() => {
    // TODO: Add domain checks to ensure secure message transfer
    window.addEventListener("message", messageReceiver);

    return () => {
      window.removeEventListener("message", messageReceiver);
    };
  }, []);

  // no errors here
  return { data, error: null, loading };
}
