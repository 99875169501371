import { IIconIds, iconSets } from "../lib/iconSets";

export function Widget({
  data,
  contacts,
}: {
  contacts: Record<string, any>;
  data: Record<string, any>;
}) {
  const paddingLiteral = data.paddingType === "LITERAL" ? "px" : "%";
  const positionLiteral = data.positionType === "LITERAL" ? "px" : "%";

  const backgroundColor = rgbStringify(data.backgroundColorInRGBA);
  const iconColor = rgbStringify(data.iconColorInRGBA);
  const textColor = rgbStringify(data.textColorInRGBA);

  const phoneNumber = contacts?.[0]?.phoneNumber;
  let prefilledMessage = contacts?.[0]?.prefilledMessage;

  const variables = {
    "{{page.url}}": window.location.href,
  };

  if (prefilledMessage) {
    for (let entry of Object.entries(variables)) {
      const [variable, value] = entry;
      prefilledMessage = prefilledMessage.replaceAll(variable, value);
    }
  }

  const link = phoneNumber
    ? `https://wa.me/${phoneNumber}?text=${prefilledMessage}`
    : `#`;

  return (
    <a
      target="_blank"
      rel="noopener noreferrer"
      href={link}
      style={{
        position: "fixed",
        zIndex: 999999,
        bottom: `${data.positionBottom}${positionLiteral}`,
        right: `${data.positionRight}${positionLiteral}`,
        backgroundColor: backgroundColor,
        borderRadius: data.borderRadius as number,
        display: "flex",
        alignItems: "center",
        textDecoration: "none",
        gap: data.gap as number,
        padding: `${data.paddingVertical}${paddingLiteral} ${data.paddingHorizontal}${paddingLiteral}`,
      }}
    >
      {data.icon && (
        <span
          style={{
            overflow: "hidden",
            width: data.iconSize as number,
            height: data.iconSize as number,
            color: iconColor,
          }}
        >
          {iconSets[data.icon as IIconIds].icon}
        </span>
      )}
      {data.text && (
        <span
          style={{
            color: textColor,
            fontFamily: `inherit`,
            fontSize: data.textSize as number,
          }}
        >
          {data.text}
        </span>
      )}
    </a>
  );
}

function rgbStringify(color: [number, number, number, number]) {
  return `rgba(${color[0]}, ${color[1]}, ${color[2]}, ${color[3] ?? 1})`;
}
