import { WidgetDataLoader } from "./components/WidgetDataLoader";
import { checkIsDevelopmentMode } from "./lib/checkIsDevelopmentMode";
import { currentScript } from "./lib/currentScript";

export function App() {
  let pathWithShopName: string;
  const isDevelopmentMode = checkIsDevelopmentMode();
  const scriptPath = currentScript?.getAttribute("src");

  if (isDevelopmentMode) {
    pathWithShopName = document.URL;
  } else if (scriptPath) {
    pathWithShopName = scriptPath;
  } else {
    return <p>Something went wrong</p>;
  }

  const url = new URL(pathWithShopName);

  const shop = url.searchParams.get("shop");

  if (!shop) {
    console.log({ shop });
    return <p>Something went wrong</p>;
  }

  return (
    <WidgetDataLoader isDevelopmentMode={isDevelopmentMode} shopName={shop} />
  );
}
