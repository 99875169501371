import React from "react";
import ReactDOM from "react-dom/client";
import { App } from "./App";
import "./index.css";
import { checkIsDevelopmentMode } from "./lib/checkIsDevelopmentMode";

function main() {
  const isDevelopmentMode = checkIsDevelopmentMode();

  const rootEl = isDevelopmentMode
    ? document.getElementById("root")
    : document.getElementById("whatsapp-customer-support-root");

  ReactDOM.createRoot(rootEl!).render(
    <React.StrictMode>
      <App />
    </React.StrictMode>
  );
}

main();
