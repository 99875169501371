import { useEffect, useState } from "react";

function getUrl(shopName: string) {
  return `${process.env.REACT_APP_BACKEND_URL}/stores/${shopName}`;
}

async function improvedFetch(url: string, options?: Record<string, any>) {
  const stream = await fetch(url);

  if (!stream.ok) {
    throw new Error(await stream.text());
  }

  const data = await stream.json();

  return data;
}

export function useWidgetSettings<D, E>(shopName: string) {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<D | null>(null);
  const [error, setError] = useState<E | null>(null);

  useEffect(() => {
    improvedFetch(`${getUrl(shopName)}/floating-widget`)
      .then((data) => {
        setData(data);
        setError(null);
      })
      .catch((e) => {
        setData(null);
        setError(e);
      })
      .finally(() => setLoading(false));
  }, [shopName, setData, setError, setLoading]);

  return { data, error, loading };
}

export function useContacts<D, E>(shopName: string) {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<D | null>(null);
  const [error, setError] = useState<E | null>(null);

  useEffect(() => {
    improvedFetch(`${getUrl(shopName)}/contacts`)
      .then((data) => {
        setData(data);
        setError(null);
      })
      .catch((e) => {
        setData(null);
        setError(e);
      })
      .finally(() => setLoading(false));
  }, [shopName, setData, setError, setLoading]);

  return { data, error, loading };
}
